<template lang="pug">
form.container.w-50
    .form-group
        label Email address
        input.form-control(v-model="email")
    .form-group
        label Password
        input.form-control(type="password", v-model="password")
    router-link(tag="button", to="/", :disabled="!(email && password)").btn.btn-primary Login
    span.text-danger.mx-3(v-if="err") {{err.message}}
</template>

<script>
import {login} from "../api";

export default {
    data() {
        return {
            err: null,
            email: "",
            password: ""
        };
    },
    beforeRouteLeave(to, from, next) {
        login(this.email.trim(), this.password).then(user => {
            localStorage.setItem("user", user.email);
            localStorage.setItem("biobank", user.biobank);
            localStorage.setItem("domain", user.domain);
            next();
        })
        .catch(err => {
            this.err = err;
            next(false);
        });
    }
}
</script>
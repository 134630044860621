<template lang="pug">
VueSelect(
    multiple,
    :options="options"
    :value="value"
    @input="oninput"
    :reduce="option => option.id"
)
    //See https://vue-select.org/guide/validation.html#required
    template(#search="{attributes, events}")
        input.vs__search(
            v-bind="attributes"
            v-on="events"
            :required="required && !value.length"
        )
    
    //See https://vue-select.org/guide/slots.html
    template(#option="option")
        span {{option.label}}
        small.ml-2.text-muted {{option.description}}
</template>

<script>
import VueSelect from "vue-select";

export default {
    components: {VueSelect},
    props: {
        "labels": Object,
        "value": Array,
        "required": {
            type: Boolean,
            default: false
        }
    },
    computed: {
        options() {
            return Object.keys(this.labels)
            .map(key => ({
                id: key,
                label: this.labels[key].label || this.labels[key],
                description: this.labels[key].description
            }));
        }
    },
    methods: {
        oninput(event){
            this.$emit("input", event);
        }
    }
}
</script>

<style scoped>
.vs__dropdown-option--highlight .text-muted{
    color: white !important;
}
</style>
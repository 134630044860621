<template lang="pug">
include common.pug
form.card(@submit.prevent="saveAlsoKnown")
    h4.card-header(v-if="biobankName || collectionName") #[.badge.badge-dark {{value.id}}] {{"Alternative Access for "+(isFromCollection ? collectionName: biobankName)}}
    //.card-header
        //-h5.card-title {{value.id}}
        //-h6.card-subtitle.text-muted {{value.name_system}} / {{value.pid}} {{value.url}}
    .card-body
        +input_if("name_system", {large: true, label: "Source Name", help: "Name of the source in which the biobank also exists", if: "!isFromCollection"})(required)
        +input_if("name_system", {large: true, label: "Source Name", help: "Name of the source in which the collection also exists", if: "isFromCollection"})(required)
        +input_if("pid", {large: true, help: "Persistent Identifier of the biobank in the other source. Either Pid or the URL must be present.", if: "!isFromCollection"})
        +input_if("pid", {large: true, help: "Persistent Identifier of the collection in the other source. Either Pid or the URL must be present.", if: "isFromCollection"})
        +input_if("url", {large: true, help: "Link to the biobank in the other source", if: "!isFromCollection"})(type="url")
        +input_if("url", {large: true, help: "Link to the collection in the other source", if: "isFromCollection"})(type="url")
    .card-body
        button.btn.btn-primary(type="submit", :disabled="!dirty || !ok()") Save changes
        //-button.btn.btn-primary.ml-2(@click.prevent="goBack()" v-if="isFromCollection") Go to collection
        //-button.btn.btn-primary.ml-2(@click.prevent="goBack()" v-if="!isFromCollection") Go to biobank
        button.btn.btn-danger.ml-2(@click.prevent="deleteAlsoKnown()", v-if="!createMode") Delete Alternative Access
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import {patchAlsoKnown, postAlsoKnown, deleteAlsoKnown, getCollection, getBiobank} from "../api";

export default {
    props: {
        "value": Object,
        "createMode": {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dirty: false,
            err: null,
            saveSuccess: false,
            cancel: true,
            isFromCollection: false,
            collectionName: null,
            biobankName: null
        }
    },
    methods: {
        ok() {
          return this.value.pid || this.value.url;
        },
        goBack() {
          // if (this.noChanges())
            this.goBackImpl();
          // else {
          //   if (confirm(`You did not save your changes! Still want to leave the form?`)) {
          //     this.goBackImpl();
          //   }
          // }
        },
        goBackImpl() {
          if (this.isFromCollection)
            this.$router.push("/collections/"+this.value.collection_id);
          else
            this.$router.push("/biobank");
        },
        noChanges() {
          return !this.dirty || !this.ok();
        },
        saveAlsoKnown() {
            // if (this.createMode && this.isFromCollection)
            (this.createMode ? postAlsoKnown(this.value) : patchAlsoKnown(this.value))
            .then(() => {
                this.err = null;
                this.dirty = false;
                this.$emit('changedAlsoKnown', false);
                this.saveSuccess = true;
                if (this.createMode) {
                  if (this.isFromCollection)
                    this.$router.push("/collections/"+this.value.collection_id);
                  else
                    this.$router.push("/biobank");
                }
            })
            .catch(err => {
                this.err = err;
                this.saveSuccess = false;
            });
        },
        deleteAlsoKnown() {
          if (confirm(`Deleting "${this.value.id}". This cannot be undone.`)) {
            deleteAlsoKnown(this.value.id)
                .then(() => {
                  this.$emit('changedAlsoKnown', false);
                  if (this.isFromCollection)
                    this.$router.push("/collections/" + this.value.collection_id);
                  else
                    this.$router.push("/biobank");
                })
                .catch(err => {
                  if (err.status === 409) {
                    err.message = `${this.value.email} is used as primary contact`
                  }
                  this.err = err;
                  this.saveSuccess = false;
                });
          }
        }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.dirty = true;
                this.$emit('changedAlsoKnown', true);
                this.saveSuccess = false;
            }
        }
    },
    created() {
      this.isFromCollection = this.value.parent_type === "collection";
      if (this.isFromCollection) {
        getCollection(this.value.collection_id).then((collection) => {
          this.collectionName = collection.name;
        })
      } else {
        getBiobank(this.value.biobank_id).then((biobank) => {
          this.biobankName = biobank.name;
        })
      }
    }
}
</script>

<template lang="pug">
    select.form-control(@change="change", required)
        option(v-for="person in filteredPeople", :value="person.id", :selected="person.id == value")
            | {{person.first_name}} {{person.last_name}} &lt;{{person.email}}&gt;
</template>

<script>
import {getPeople} from "../api";
import {filteredPeople} from "./common";

export default {
    props: ["value"],
    data() {
        return {
            people: []
        };
    },
    created() {
        getPeople()
        .then(people => {
            this.people = people;
            if (!this.value && people.length > 0)
                this.$emit("input", people[0].id);
        });
    },
    methods: {
        change(e) {
            this.$emit("input", e.target.value);
        }
    },
    computed: {filteredPeople}
}
</script>

<template lang="pug">
.alert.alert-warning(v-if="err") {{err.message}}
div(v-else)
    CollectionTable(:collections="collections", :showSubcollections="false")
    router-link(to="/collection-new").btn.btn-success Add Collection
    hr
</template>

<script>
import CollectionTable from "./CollectionTable.vue";
import {getCollectionsByBiobank} from "../api";

export default {
    components: {CollectionTable},
    data() {
        return {
            err: null,
            collections: []
        }
    },
    created() {
        getCollectionsByBiobank(localStorage.getItem("biobank"))
        .then(collections => this.collections = collections)
        .catch(err => this.err = err);
    },
}
</script>

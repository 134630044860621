<template lang="pug">
include common.pug
form.card(@submit.prevent="savePerson")
    .card-header
        h5.card-title {{value.first_name}} {{value.last_name}}
        h6.card-subtitle.text-muted {{value.email}}
    .card-body
        .input-group.mb-2
            input.form-control(v-model="value.title_before_name", placeholder="(Title)")
            input.form-control.w-25(v-model="value.first_name", placeholder="(First name)")
            input.form-control.w-25(v-model="value.last_name", placeholder="(Last name)")
            input.form-control(v-model="value.title_after_name", placeholder="(Title)")
        +input("email", {large: true})(type="email")
        +input("phone", {large: true})(type="phone")
        +input("address", {large: true})
        +input("zip")
        +input("city")
        +input("country")(disabled)
        +input("role")
    .card-body
        button.btn.btn-primary(type="submit", :disabled="!dirty") Save changes
        //-button.btn.btn-primary.ml-2(@click.prevent="goBack()") Go to people list
        button.btn.btn-danger.ml-2(@click.prevent="deletePerson()", v-if="!createMode") Delete person
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import {patchPerson, postPerson, deletePerson} from "../api";

export default {
    props: {
        "value": Object,
        "createMode": {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dirty: false,
            err: null,
            saveSuccess: false,
            cancel: true
        }
    },
    methods: {
        noChanges() {
            return !this.dirty;
        },
        goBack() {
            this.$router.push("/people");
        },
        savePerson() {
            (this.createMode ? postPerson(this.value) : patchPerson(this.value))
            .then(() => {
                this.err = null;
                this.dirty = false;
                this.$emit('changedPerson', false);
                this.saveSuccess = true;
                if (this.createMode)
                    this.$router.push("/people");
            })
            .catch(err => {
                this.err = err;
                this.saveSuccess = false;
            });
        },
        deletePerson() {
          if (confirm(`Deleting "${this.value.id}". This cannot be undone.`)) {
            deletePerson(this.value.id)
                .then(() => {
                  this.$emit('changedPerson', false);
                  this.$router.push("/people");
                })
                .catch(err => {
                  if (err.status === 409) {
                    err.message = `${this.value.email} is used as primary contact`
                  }
                  this.err = err;
                  this.saveSuccess = false;
                });
          }
        }
    },
    watch: {
        value: {
            deep: true,
            handler() {
                this.dirty = true;
                this.saveSuccess = false;
                this.$emit('changedPerson', true);
            }
        }
    }
}
</script>

<template lang="pug">
    PersonEditCard(v-model="person", :createMode="true" @changedPerson="onChanged")
</template>

<script>
import PersonEditCard from "./PersonEditCard.vue";
import {country} from "../../config.json";

export default {
    components: {PersonEditCard},
    data() {
        return {
            person: {country},
            isChanged: false
        };
    },
    // beforeRouteLeave(to, from, next) {  //check here
    //   if (! this.isChanged) {
    //     next();
    //   }
    //   else {
    //     if (confirm(`You did not save your changes! Still want to leave the form?`)) {
    //       next();
    //     } else {
    //       next(false);
    //     }
    //   }
    // },
    methods: {
      onChanged(isChanged) {
        this.isChanged = isChanged;
      }
    }
}
</script>

<template lang="pug">
    CollectionEditCard(v-model="collection" :createMode="true" @changedCollection="onChanged")
</template>

<script>
import CollectionEditCard from "./CollectionEditCard.vue";
import {country, badCharsRegex} from "../../config.json";

const badChars = new RegExp(badCharsRegex, "g");

export default {
    components: {CollectionEditCard},
    data() {
        return {
            collection: {
                country,
                biobank: localStorage.getItem("biobank"),
                type: ["SAMPLE"],
                materials: ["NAV"],
                data_categories: ["NAV"],
                storage_temperatures: [],
                network: [],
                sex: [],
                diagnosis_available: [],
                body_part_examined: [],
                imaging_modality: [],
                image_dataset_type: [],
                sop_New: {
                  "sample_processing_sop" : false,
                  "sample_transport_sop" : false,
                  "sample_storage_sop" : false,
                  "data_processing_sop" : false,
                  "data_transport_sop" : false,
                  "data_storage_sop" : false
                },
                access_fee_New: { images: false, samples: false, data: false},
                access_joint_project_New: { images: false, samples: false, data: false}
                //all ARRAY attributes must be listed here
            },
          isChanged: false
        };
    },
    watch: {
        "collection.name"(name) {
            let prefix = this.collection.biobank + ":collection:";
            if (this.collection.parent_collection)
                prefix = this.collection.parent_collection + ":";
            this.collection.id = prefix + name.replace(badChars, "");
        }
    },
    created() {
      Object.assign(this.collection, this.$route.query);
      // console.log("created")
    },
  // beforeRouteLeave(to, from, next) {  //check here
  //   if (! this.isChanged) next();
  //   else {
  //     if (confirm(`You did not save your changes! Still want to leave the form?`))  next();
  //     else next(false);
  //   }
  // },
  methods: {
    onChanged(isChanged) {
      console.log(this.isChanged);
      this.isChanged = isChanged;
    }
  }
}
</script>

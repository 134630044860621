<template lang="pug">
div
    .alert.alert-warning(v-if="err") {{err.message}}
    AlsoKnownEditCard(v-if="alsoKnown" v-model="alsoKnown" @changedAlsoKnown="onChanged")

    router-link(v-if="alsoKnown && isFromCollection"
        :to="{path: '/also-known-new', query: {parent_type: 'collection', collection_id: alsoKnown.collection_id}}"
      ).btn.btn-success.mb-3 Add Alternative Access
    router-link(v-if="alsoKnown && !isFromCollection"
        :to="{path: '/also-known-new', query: {parent_type: 'biobank', biobank_id: alsoKnown.biobank_id}}"
    ).btn.btn-success.mb-3 Add Alternative Access

</template>

<script>
import {getAlsoKnown} from "../api";
import AlsoKnownEditCard from "./AlsoKnownEditCard";
export default {
    components: {AlsoKnownEditCard},
    data() {
        return {
            alsoKnown: null,
            isFromCollection: false,
            isChanged: false,
            err: null
        }
    },
    created() {
        this.fetchAlsoKnown(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next){
        this.fetchAlsoKnown(to.params.id);
        next()
    },
    // beforeRouteLeave(to, from, next) {  //check here
    //   if (! this.isChanged) {
    //     next();
    //   }
    //   else {
    //     if (confirm(`You did not save your changes! Still want to leave the form?`)) {
    //       next();
    //     } else {
    //       next(false);
    //     }
    //   }
    // },
    methods: {
        fetchAlsoKnown(id) {
            return getAlsoKnown(id)
            .then((alsoKnown) => {
              this.alsoKnown = alsoKnown;
              this.isFromCollection = this.alsoKnown.parent_type === "collection";
            })
            .catch(err => this.err = err);
        },
        onChanged(isChanged) {
          console.log(isChanged);
          this.isChanged = isChanged;
        }

    }
}
</script>

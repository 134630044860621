<template lang="pug">
div
    .alert.alert-warning(v-if="err") {{err.message}}
    PersonEditCard(v-if="person" v-model="person" @changedPerson="onChanged")
</template>

<script>
import {getPerson} from "../api";
import PersonEditCard from "./PersonEditCard";
export default {
    components: {PersonEditCard},
    data() {
        return {
            person: null,
            err: null,
            isChanged: false
        }
    },
    created() {
        this.fetchPerson(this.$route.params.id);
    },
    beforeRouteUpdate(to, from, next){
        this.fetchPerson(to.params.id);
        next()
    },
    // beforeRouteLeave(to, from, next) {  //check here
    //   if (! this.isChanged) {
    //     next();
    //   }
    //   else {
    //     if (confirm(`You did not save your changes! Still want to leave the form?`)) {
    //       next();
    //     } else {
    //       next(false);
    //     }
    //   }
    // },
    methods: {
        fetchPerson(id) {
            return getPerson(id)
            .then(person => this.person = person)
            .catch(err => this.err = err);
        },
        onChanged(isChanged) {
          this.isChanged = isChanged;
        }
    }
}
</script>

<template lang="pug">
VueSelect(
    multiple
    :value="selectedDiseases"
    @input="oninput"
    @search="searchDiagnosis"
    :options="options"
    :filterable="false"
)
    template(v-slot:option="option")
        div #[strong {{option.code}}] {{option.label}}
    template(v-slot:selected-option="option")
        div {{option.code || option.label}}
</template>

<script>
import debounce from "lodash.debounce";
import map from "lodash.map";
import VueSelect from "vue-select";
import {searchDiseaseTypes} from "../api";

export default {
    components: {VueSelect},
    props: ["value"],
    data() {
        return { options: [] }
    },
    computed: {
        selectedDiseases() {
            if (this.value == null) {
              return [];
            }
            return this.value.map(id => {
                return {
                    id,
                    label: id.replace(/^urn:miriam:icd:/, "")
                }
            });
        }
    },
    methods: {
        searchDiagnosis: debounce(function (query, loading){
            searchDiseaseTypes(query)
            .then(diseaseTypes => {
                this.options = diseaseTypes;
            })
            .finally(() => loading(false));
            loading(true);
        }, 350),
        oninput(value) {
            this.$emit("input", map(value, "id"));
        }
    }
}
</script>

<style scoped>

</style>

<template lang="pug">
include common.pug
form.card(@submit.prevent="saveBiobank")
    +card-header
    .card-body
        .form-row
            .col: +input("name", {help: "≤ 60 characters"})(required)
            .col: +input("acronym", {help: "≤ 20 characters"})
        +textarea("description", {help: "Description of the biobank in English"})(required)
        //-+input("bioresource_reference", {help: "Reference to be cited when the biobank is used for research", large: true})(maxlength=255)
        +input("url", {label: "Website URL", large: true})(type="url")
        +input("juridical_person", {help: "Name of the legal entity of the biobank", large: true})
        +input("country", {help: "The country in which the biobank resides"})#country(disabled, required)
        //-.card-body
        //-h5 IT-Support
        //-+checkbox("it_support_available", "Biobank has dedicated IT support")
        //-+input("it_staff_size", {
        //-   label: "IT Staff Size",
        //-    if: "value.it_support_available || value.it_staff_size",
        //-   help: "Size of the IT staff of the biobank"
        //-})(type="number", min=0)
        //-+checkbox("is_available", "Internal information management system for tracking biobank processes")
        //-+checkbox("his_available", "Connection to a hospital information system (HIS)")
        //-+checkbox("partner_charter_signed", "BBMRI-ERIC partner charter signed")
    .card-body
        h5 Contact information
        .form-inline.mb-2
            label Biobank Head
                //-+required
            person-select.mx-3(v-model="value.head")
            small Natural person in charge of the biobank
            //-        .form-inline.mb-2
            //-            +input2("roleNew", {label: "Head Role", help: "typically, principal investigator or director"})
        .form-inline.mb-2
            label Primary External Contact
                +required
            person-select.mx-3(v-model="value.contact")
        .form-inline.mb-2
            +input("location", { label: "City", help: "The city where the biobank resides"})
        //-.form-inline.mb-2
            label Primary Site Location
            +latLong
        .form-inline.mb-2
            label Networks
            NetworksSelect.mx-3(v-model="value.network" style="min-width: 15%")
        .form-group.mt-3
            h6 Capabilities
            MultipleSelect(:labels="BiobankCapabilities", v-model="value.capabilities")
            small List of the capabilities that the biobank can offer to a researcher as a service
        +checkbox("collaboration_commercial", "Biobank is able to participate in commercial collaborations")
        +checkbox("collaboration_non_for_profit", "Biobank is able to participate in collaborations with not-for-profit organisations")
    .card-body
        h5 Alternative Access
        AlsoKnownTable(:alsoKnowns="alsoknown", :parent_type="'biobank'", :parent_id="value.id")
    .card-body
        //- button.btn.btn-primary(type="submit", :disabled="!dirty && !roleChanged()") Save changes
        button.btn.btn-primary(type="submit", :disabled="!dirty") Save changes
        span.text-danger.mx-sm-3(v-if="saveErr") {{saveErr.message}}
        span.text-success.mx-sm-3(v-if="saveSuccess") Changes have been saved!
</template>

<script>
import PersonSelect from "./PersonSelect.vue";
import NetworksSelect from "./NetworksSelect.vue";
import MultipleSelect from "./MultipleSelect.vue";
import BiobankCapabilities from "../labels/BiobankCapabilities.json";
import {getAlsoKnownByBiobank, getAlsoKnownByCollection, getPerson, patchBiobank, patchPerson} from "../api";
import AlsoKnownTable from "./AlsoKnownTable";

export default {
  components: {AlsoKnownTable, PersonSelect, NetworksSelect, MultipleSelect},
  data(){
    return {
      saveSuccess: false,
      saveErr: null,
      dirty: false,
      alsoknown: [],
      BiobankCapabilities,
    }
  },
  props: {
    value: {type: Object, required: true},
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.saveSuccess = false;
        this.dirty = true;
      }
    }
  },
  methods: {
    // roleChanged() {
    //   return this.roleNew !== this.roleNewOrig;
    // },
    saveBiobank(){
      patchBiobank(this.value)
          .then(() => {
            this.saveSuccess = true;
            this.saveErr = null;
            this.dirty = false;
            // getPerson(this.value.head).then((p) => {
            //   p.role = this.roleNew;
            //   patchPerson(p).then(() => {
            //     this.saveSuccess = true;
            //     this.saveErr = null;
            //     this.dirty = false;
            //     this.roleNewOrig = this.roleNew;
            //   });
            // })
          })
          .catch(err => {
            this.saveSuccess = false;
            this.saveErr = err;
          })
    }
  },
  created() {
    getAlsoKnownByBiobank(this.value.id)
        .then(alsoKnownList => this.alsoknown = alsoKnownList)
  }

}
</script>

<style scoped>
#country { width: 4em; }
</style>

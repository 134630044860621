<template lang="pug">
form.w-50.m-auto(@submit.prevent="changePassword")
    h4 Change Password
    .form-group
        label Old Password
        input.form-control(type="password", v-model="oldPassword", required)
    .form-group
        label New Password
        input.form-control(type="password", v-model="newPassword", required, minlength=8)
    .form-group
        label Repeat New Password
        input.form-control(type="password", v-model="newPasswordRepeat", required, :class="{'is-invalid': doesNotMatch}")
        .invalid-feedback(v-if="doesNotMatch") Passwords do not match
    .form-group
        button.btn.btn-primary(type="submit") Change Password
        span.text-danger.mx-sm-3(v-if="err") {{err.message}}
        span.text-success.mx-sm-3(v-if="success") Password changed!
</template>

<script>
import {changePassword} from "../api";
export default {
    data() {
        return {
            err: null, success: null,
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: ""
        }
    },
    computed: {
        doesNotMatch() { return this.newPassword !== this.newPasswordRepeat }
    },
    methods: {
        changePassword() {
            changePassword(this.oldPassword, this.newPassword)
            .then(() => {
                this.err = null;
                this.success = true;
            })
            .catch(err => {
                this.err = err;
                this.success = null;
            })
        }
    }
}
</script>
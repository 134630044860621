<template lang="pug">
    select.form-control(@change="change", required)
        option(v-if="person.id != null" v-for="person in filteredPeopleWithEmptyOption", :value="person.id", :selected="person.id === value")
            | {{person.first_name}} {{person.last_name}} &lt;{{person.email}}&gt;
        option(disabled :selected="value == null")
            | --- Please select a person ---
</template>

<script>
import {getPeople} from "../api";
import {filteredPeopleWithEmptyOption} from "./common";

export default {
    props: ["value"],
    data() {
        return {
            people: []
        };
    },
    created() {
        getPeople()
        .then(people => {

            this.people = people;
            if (this.value == null && people.length > 0)
                this.$emit("input", null);
        });
    },
    methods: {
        change(e) {
            this.$emit("input", e.target.value);
        }
    },
    computed: {filteredPeopleWithEmptyOption}
}
</script>

<template lang="pug">
.alert.alert-warning(v-if="err") {{err.message}}
div(v-else)
    .card(v-for="person in filteredPeople").mb-2
        .card-header
            router-link(:to="'/people/' + person.id")
                h5.card-title {{person.first_name}} {{person.last_name}}
                h6.card-subtitle.text-muted {{person.email}}
    router-link.btn.btn-success.my-3(to="/person-new") Add Person
</template>

<script>
import {getPeople} from "../api";
import {filteredPeople} from "./common";

export default {
    data() {
        return {
            err: null,
            people: []
        }
    },
    created() {
        getPeople()
        .then(people => this.people = people)
        .catch(err => this.err = err);
    },
    computed: { filteredPeople }
}
</script>

<template lang="pug">
    div
        .alert.alert-warning(v-if="err") {{err.message}}
        BiobankEditCard.mb-sm-3(
            v-if="biobank",
            v-model="biobank"
        )
</template>

<script>
import BiobankEditCard from "./BiobankEditCard.vue";
import {getBiobank, getPerson} from "../api";

export default {
    components: {BiobankEditCard},
    data() {
        return {
            biobank: null,
            err: null
        }
    },
    created() {
        getBiobank(localStorage.getItem("biobank"))
        .then((biobank) => {
          this.biobank = biobank;
        })
        .catch(err => this.err = err);
    }
}
</script>

<template lang="pug">
    AlsoKnownEditCard(v-model="alsoKnown" :createMode="true" @changedAlsoKnown="onChanged")
</template>

<script>
import {country, badCharsRegex} from "../../config.json";
import AlsoKnownEditCard from "./AlsoKnownEditCard";

const badChars = new RegExp(badCharsRegex, "g");

export default {
    components: {AlsoKnownEditCard},
    data() {
        return {
            alsoKnown: {},
            isChanged: false
        };
    },
    watch: {
      "alsoKnown.name_system"(name_system) {
        let prefix = "bbmri-eric:akiID:";
        // let prefix = "bbmri-eric:akiID:"+(this.alsoKnown.parent_type === "collection" ? this.alsoKnown.collection_id : this.alsoKnown.biobank_id) + ":";
        this.alsoKnown.id = prefix + ((name_system||"")+(this.alsoKnown.pid||"")+(this.alsoKnown.url||"")).replace(badChars, "");
      },
      "alsoKnown.pid"(pid) {
        let prefix = "bbmri-eric:akiID:";
        // let prefix = "bbmri-eric:akiID:"+(this.alsoKnown.parent_type === "collection" ? this.alsoKnown.collection_id : this.alsoKnown.biobank_id) + ":";
        this.alsoKnown.id = prefix + ((this.alsoKnown.name_system||"")+(pid||"")+(this.alsoKnown.url||"")).replace(badChars, "");
      },
      "alsoKnown.url"(url) {
        let prefix = "bbmri-eric:akiID:";
        // let prefix = "bbmri-eric:akiID:"+(this.alsoKnown.parent_type === "collection" ? this.alsoKnown.collection_id : this.alsoKnown.biobank_id) + ":";
        this.alsoKnown.id = prefix + ((this.alsoKnown.name_system||"")+(this.alsoKnown.pid||"")+(url||"")).replace(badChars, "");
      }

    },
    created() {
        Object.assign(this.alsoKnown, this.$route.query);
    },
  // beforeRouteLeave(to, from, next) {  //check here
  //   if (! this.isChanged) {
  //     next();
  //   }
  //   else {
  //     if (confirm(`You did not save your changes! Still want to leave the form?`)) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   }
  // },
  methods: {
    onChanged(isChanged) {
      this.isChanged = isChanged;
    }
  }
}
</script>

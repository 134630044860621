<template lang="pug">
div.mb-sm-3
    table.table.table-striped
        thead: tr
            th(scope="col") {{showSubcollections ? "Subcollection" : "Collection"}}
            th(scope="col") Type
            th(scope="col") Materials
            th(scope="") #Samples
            //-th(scope="") Note
        tbody: tr(v-for="collection in collections" v-if="!collection.parent_collection || showSubcollections")
            td
                router-link(:to="'/'+(showSubcollections ? 'collections' : 'collections')+'/' + collection.id") {{collection.name}}
                div(v-if="subcollectionCount[collection.id]")
                    | (+ {{subcollectionCount[collection.id]}} subcollections)
            td {{ getCollectionType(collection) }}
            td {{ getCollectionMaterials(collection) }}
            td {{ collection.size }}
            //-td
              span.red {{ headMessage(collection) }}
</template>
<script>
import countBy from "lodash.countby";
import CollectionTypeLabels from "../labels/CollectionTypes.json";
import MaterialTypeLabels from "../labels/MaterialTypes.json";

export default {
    props: {
        "collections" : Array,
        "showSubcollections": {
            type: Boolean,
            default: true
        }
    },
    methods: {
        getCollectionType(collection) {
//            console.log(collection);
//            console.log(collection.type[0]);
            return collection.type && collection.type
            .map(t => CollectionTypeLabels[t].label)
            .join(", ");
        },
        getCollectionMaterials(collection) {
            return collection.materials && collection.materials
            .map(m => MaterialTypeLabels[m])
            .join(", ");
        },
        headMessage(collection) {
          return collection.head == null ? "Head is not set" : "";
        }
    },
    computed: {
        subcollectionCount() {
            return countBy(this.collections, "parent_collection")
        }
    }
}
</script>
<style scoped>
.red {
  color: red;
}
</style>

<template lang="pug">
    VueSelect(
        multiple
        :options="networks"
        :reduce="network => network.id"
        :getOptionLabel="option => option.acronym || option.name"
        :value="value"
        @input="oninput"
    )
</template>

<script>
import VueSelect from "vue-select";
import {getNetworks} from "../api";

export default {
    components: {VueSelect},
    props: {"value": Array},
    data() {
        return { networks: [] };
    },
    created() {
        getNetworks()
        .then(networks => this.networks = networks);
    },
    methods: {
        oninput(selectedNetworks) {
            this.$emit("input", selectedNetworks);
        }
    }
}
</script>
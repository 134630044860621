export function filteredPeople() {
    const domain = localStorage.getItem("domain");
    return this.people
    .filter(person => person.email && person.email.endsWith(domain));
}

export function filteredPeopleWithEmptyOption() {
    const domain = localStorage.getItem("domain");
    return this.people
        .filter(person => person.id == null || (person.email && person.email.endsWith(domain)));
}
